import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { DialogStatement, dialogStatementFragment } from './DialogStatement';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { DialogTopicSegmentFragmentFragment } from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const TopicWrapper = styled.div`
  padding: 5px 6px 1px;
`;

const SegmentWrapper = styled.div`
  padding: 5px 6px 1px;
`;

export const dialogTopicSegmentFragment = gql`
  fragment dialogTopicSegmentFragment on DialogTopicSegment {
    statements {
      ...dialogStatementFragment
    }
    topic {
      text
      localizedText
    }
  }
  ${dialogStatementFragment}
`;

export const DialogTopicSegment = (
  item: DialogTopicSegmentFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <TopicWrapper>
        Topic:
        <LocalizedText item={item.topic} />
      </TopicWrapper>
      <SegmentWrapper>
        {item.statements.map((child, i) => (
          <DialogStatement key={i} {...child} />
        ))}
      </SegmentWrapper>
    </CoursewareItemContainer>
  );
};
