import React, { useEffect, useState, ComponentType } from 'react';

export function withTextSelection<Props>(
  WrappedComponent: ComponentType<Props>,
): ComponentType<Props> {
  return function WithTextSelection(props: Props) {
    const selection = useGetSelection();
    return <WrappedComponent {...props} textSelection={selection} />;
  };
}

const useGetSelection = () => {
  const [selection, setSelection] = useState<string | undefined>();

  useEffect(() => {
    const evListener = (ev: MouseEvent) => {
      ev.preventDefault();
      const selectedText = window?.getSelection()?.toString();
      if (selectedText) {
        setSelection(selectedText);
      } else {
        setSelection(undefined);
      }
    };

    // eslint-disable-next-line no-undef
    const html = (document ?? {})?.querySelector('html');
    html?.addEventListener('mouseup', evListener, true);

    return () => html?.removeEventListener('mouseup', evListener, true);
  }, []);

  return selection;
};
