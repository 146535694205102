import { Box } from '@material-ui/core';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import flatten from 'lodash.flatten';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { exists } from '../../../util';
import { SmartCourseLessonNavQuery } from '../../../generated/graphql';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';

const smartCourseLessonNavQuery = gql`
  query SmartCourseLessonNav {
    admin {
      smartCourses {
        id
        lessons {
          id
        }
      }
    }
  }
`;

interface SmartCourseLessonNavigationProps {
  lessonId: string;
}

export const SmartCourseLessonNavigation = (
  props: SmartCourseLessonNavigationProps,
) => {
  const { lessonId } = props;

  const { loading, error, data } = useQuery<SmartCourseLessonNavQuery>(
    smartCourseLessonNavQuery,
  );
  if (error) return <GraphQLErrorDisplay error={error} />;
  if (loading || !data?.admin?.smartCourses) return null;
  const allLessonIds = flatten(
    data?.admin.smartCourses.map(course =>
      course?.lessons.map(lesson => lesson.id),
    ),
  ).filter(exists);
  const lessonIndex = allLessonIds.indexOf(lessonId);
  if (lessonIndex === -1) return null;
  const nextLessonLink =
    lessonIndex < allLessonIds.length - 1 ? (
      <Link to={`/smartCourse/lesson/${allLessonIds[lessonIndex + 1]}`}>
        next →
      </Link>
    ) : null;
  const prevLessonLink =
    lessonIndex > 0 ? (
      <Box marginRight={2} display="inline-block">
        <Link to={`/smartCourse/lesson/${allLessonIds[lessonIndex - 1]}`}>
          ← previous
        </Link>
      </Box>
    ) : null;
  return (
    <Box marginTop={2} fontSize="12px">
      {prevLessonLink}
      {nextLessonLink}
    </Box>
  );
};
