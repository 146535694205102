import React, { ChangeEvent } from 'react';
import {
  Button,
  DialogContent,
  DialogActions,
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Radio,
} from '@material-ui/core';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import flatten from 'lodash.flatten';
import {
  LexicalaLookupQuery,
  LexicalaLookupQueryVariables,
  EnhanceSenseInfoQuery,
  EnhanceSenseInfoQueryVariables,
  EnhanceSenseWithTranslationsMutation,
  EnhanceSenseWithTranslationsMutationVariables,
  DictionarySenseEnhancementSource,
} from '../../generated/graphql';
import { GraphQLErrorDisplay } from '../GraphQLErrorDisplay';
import {
  DictionarySenseCard,
  dictionarySenseCardSenseFragment,
} from '../DictionarySenseCard';
import { StepProps } from './StepProps';

interface EnhanceSenseStepProps extends StepProps {
  senseId: string;
  lessonId: string;
}

export const EnhanceSenseStep: React.FC<EnhanceSenseStepProps> = ({
  onClose,
  onStepComplete,
  senseId,
}) => {
  const [selectedLexicalaSenseId, setSelectedLexicalaSenseId] = React.useState<
    string | undefined
  >();

  const [
    enhanceSense,
    { error: enhanceSenseError, loading: enhanceSenseLoading },
  ] = useMutation<
    EnhanceSenseWithTranslationsMutation,
    EnhanceSenseWithTranslationsMutationVariables
  >(enhanceSenseWithTranslations);

  const { data: senseNodeData, error: senseNodeDataError } = useQuery<
    EnhanceSenseInfoQuery,
    EnhanceSenseInfoQueryVariables
  >(enhanceSenseInfoQuery, {
    variables: {
      id: senseId,
    },
  });

  const [
    lexicalaLookup,
    {
      data: lexicalaLookupData,
      loading: lexicalaLoading,
      error: lexicalaLookupError,
    },
  ] = useLazyQuery<LexicalaLookupQuery, LexicalaLookupQueryVariables>(
    lexicalaLookupQuery,
  );

  const senseResult =
    senseNodeData?.node?.__typename === 'DictionarySense'
      ? senseNodeData.node
      : undefined;

  const lexicalaResults =
    lexicalaLookupData?.admin?.adminLexicalaSearch?.results;

  React.useEffect(() => {
    if (senseResult) {
      lexicalaLookup({
        variables: {
          word: senseResult.word.word,
        },
      });
    }
  }, [senseResult]);

  const onLinkSensesClicked = async () => {
    const lexicalaSense = flatten(
      lexicalaResults?.map(words => words.senses),
    ).find(sense => sense?.id === selectedLexicalaSenseId);

    if (!lexicalaSense) return;

    if (lexicalaSense.translations) {
      const translations = lexicalaSense.translations.map(
        ({ language, translation }) => ({
          language,
          translations: [translation],
        }),
      );

      await enhanceSense({
        variables: {
          input: {
            senseId,
            source: DictionarySenseEnhancementSource.Lexicala,
            translations,
          },
        },
      });
    }
    onStepComplete();
  };

  const handleSenseSelected = (evt: ChangeEvent<HTMLInputElement>) =>
    setSelectedLexicalaSenseId(evt.target.value);

  return (
    <>
      <DialogContent>
        <Box mb={2}>
          {senseResult && (
            <DictionarySenseCard sense={senseResult}></DictionarySenseCard>
          )}
        </Box>
        <Box>
          <Typography variant="h5" component="h2">
            Lexicala results
          </Typography>
          {lexicalaLoading && <CircularProgress />}
          {lexicalaResults?.map(lexicalaWord =>
            lexicalaWord.senses.map(
              sense =>
                sense && (
                  <Box key={sense.id} display="flex" flexDirection="row" m={1}>
                    <Box>
                      <Radio
                        checked={selectedLexicalaSenseId === sense.id}
                        onChange={handleSenseSelected}
                        value={sense.id}
                      />
                    </Box>
                    <Box flex={1}>
                      <Card>
                        <CardContent>
                          <Box mb={2}>
                            <Typography variant="h5" component="h2">
                              {lexicalaWord.headword}
                            </Typography>
                            <Typography color="textSecondary">
                              {lexicalaWord.pos}
                            </Typography>
                          </Box>
                          <Typography variant="body2" component="p">
                            {sense.definition}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                ),
            ),
          )}
          {lexicalaResults?.length === 0 && !lexicalaLoading && (
            <Typography variant="body2">No results found</Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          disabled={!selectedLexicalaSenseId || enhanceSenseLoading}
          onClick={onLinkSensesClicked}
        >
          Link Senses
        </Button>
      </DialogActions>
      <GraphQLErrorDisplay
        error={lexicalaLookupError || enhanceSenseError || senseNodeDataError}
      />
    </>
  );
};

const enhanceSenseWithTranslations = gql`
  mutation enhanceSenseWithTranslations(
    $input: AdminCreateTranslationsDictionaryEnhancementInput!
  ) {
    adminCreateTranslationsDictionaryEnhancement(input: $input) {
      word {
        id
        senses {
          id
          ...dictionarySenseCardSense
        }
      }
    }
  }

  ${dictionarySenseCardSenseFragment}
`;

const enhanceSenseInfoQuery = gql`
  query enhanceSenseInfo($id: ID!) {
    node(id: $id) {
      ... on DictionarySense {
        id
        ...dictionarySenseCardSense
        word {
          id
          word
        }
      }
    }
  }

  ${dictionarySenseCardSenseFragment}
`;

const lexicalaLookupQuery = gql`
  query lexicalaLookup($word: String!) {
    admin {
      adminLexicalaSearch(word: $word) {
        results {
          headword
          pos
          senses {
            id
            definition
            translations {
              language
              translation
            }
          }
        }
      }
    }
  }
`;
