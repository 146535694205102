import React, { ChangeEvent } from 'react';
import {
  Button,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  Typography,
  CircularProgress,
  Radio,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  DictionaryLookupQuery,
  DictionaryLookupQueryVariables,
  StudyLanguage,
} from '../../generated/graphql';
import { GraphQLErrorDisplay } from '../GraphQLErrorDisplay';
import { vocabLocationChipDataFragment } from '../VocabLocationChip';
import {
  DictionarySenseCard,
  dictionarySenseCardSenseFragment,
} from '../DictionarySenseCard';
import { StepProps } from './StepProps';

export interface TagVocabStepProps extends StepProps {
  onStepComplete: (senseId: string) => void;
  lessonId: string;
  searchWord?: string;
  isSupplemental: boolean;
  language?: StudyLanguage;
}

export const TagVocabStep: React.FC<TagVocabStepProps> = ({
  lessonId,
  onClose,
  onStepComplete,
  searchWord,
  isSupplemental,
  language,
}) => {
  const [word, setWord] = React.useState(searchWord ?? '');
  const [selectedSenseId, setSelectedSenseId] = React.useState<
    string | undefined
  >();

  const [searchLanguage, setSearchLanguage] = React.useState(
    language ?? StudyLanguage.En.toString(),
  );

  React.useEffect(() => {
    if (searchWord) setWord(searchWord);
  }, [searchWord]);

  const [
    dictionaryLookup,
    {
      data: dictionaryResults,
      error: dictionaryLookupError,
      loading: dictionaryLookupLoading,
    },
  ] = useLazyQuery<DictionaryLookupQuery, DictionaryLookupQueryVariables>(
    dictionaryLookupQuery,
  );

  const [
    tagVocab,
    { loading: tagVocabLoading, error: tagVocabError },
  ] = useMutation(tagVocabMutation);

  const onSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) =>
    setWord(event.target.value);

  const doDictionaryLookup = () => {
    dictionaryLookup({
      variables: { word, lessonId, language: searchLanguage.toLowerCase() },
    });
  };

  const handleSenseSelected = (evt: ChangeEvent<HTMLInputElement>) =>
    setSelectedSenseId(evt.target.value);

  const onTagVocabClicked = async () => {
    if (selectedSenseId) {
      await tagVocab({
        variables: {
          lessonId,
          senseId: selectedSenseId,
          isSupplemental,
          word: word.toLowerCase(),
        },
      });
      onStepComplete(selectedSenseId);
      setSelectedSenseId(undefined);
    }
  };

  const words = dictionaryResults?.admin?.adminDictionaryLookup;
  const taggedVocab =
    dictionaryResults?.node?.__typename === 'SmartCourseLesson'
      ? dictionaryResults?.node.vocabLocations?.map(
          vocabLocation => vocabLocation.sense?.id,
        )
      : [];

  return (
    <>
      <DialogContent>
        <Box minHeight={120}>
          <form
            onSubmit={evt => {
              evt.preventDefault();
              doDictionaryLookup();
            }}
          >
            <Box display="flex" flexDirection="row">
              <TextField
                value={word}
                fullWidth
                label="Dictionary Lookup"
                onChange={onSearchQueryChange}
              />
              <Select
                value={searchLanguage}
                onChange={event =>
                  setSearchLanguage(
                    (event.target.value as string).toUpperCase(),
                  )
                }
                label="Language"
              >
                {Object.values(StudyLanguage).map(lang => (
                  <MenuItem key={lang} value={lang}>
                    {lang}
                  </MenuItem>
                ))}
              </Select>
              <Button type="submit">Search</Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
      <DialogContent>
        {dictionaryLookupLoading && <CircularProgress />}
        {words?.length === 0 && (
          <Typography variant="h5" component="h2">
            No Results
          </Typography>
        )}
        {words ? (
          <Box>
            {words?.map(wordResult =>
              wordResult.senses.map(sense => {
                return (
                  <Box display="flex" flexDirection="row" key={sense.id} m={1}>
                    <Box>
                      <Radio
                        checked={selectedSenseId === sense.id}
                        onChange={handleSenseSelected}
                        value={sense.id}
                        disabled={taggedVocab?.includes(sense.id)}
                      />
                    </Box>
                    <Box flex={1}>
                      <DictionarySenseCard
                        sense={sense}
                        headerText={
                          taggedVocab?.includes(sense.id)
                            ? 'Tagged to the current lesson'
                            : undefined
                        }
                      />
                    </Box>
                  </Box>
                );
              }),
            )}
          </Box>
        ) : null}
        <GraphQLErrorDisplay error={dictionaryLookupError || tagVocabError} />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          onClick={onTagVocabClicked}
          disabled={!selectedSenseId || tagVocabLoading}
        >
          Tag Vocab
        </Button>
      </DialogActions>
    </>
  );
};

const dictionaryLookupQuery = gql`
  query dictionaryLookup($word: String!, $lessonId: ID!, $language: String) {
    admin {
      adminDictionaryLookup(word: $word, language: $language) {
        id
        word
        senses {
          ...dictionarySenseCardSense
        }
      }
    }

    node(id: $lessonId) {
      ... on SmartCourseLesson {
        id
        vocabLocations {
          id
          sense {
            id
          }
        }
      }
    }
  }
  ${dictionarySenseCardSenseFragment}
`;

const tagVocabMutation = gql`
  mutation TagVocab(
    $lessonId: ID!
    $senseId: ID!
    $isSupplemental: Boolean
    $word: String
  ) {
    adminCreateVocabLocation(
      input: {
        senseId: $senseId
        lessonId: $lessonId
        isSupplemental: $isSupplemental
        word: $word
      }
    ) {
      vocabLocation {
        id
        lesson {
          id
          vocabLocations {
            ...vocabLocationChipData
            isSupplemental
          }
        }
        smartCourseLesson {
          id
          vocabLocations {
            ...vocabLocationChipData
            isSupplemental
          }
        }
        isSupplemental
      }
    }
  }
  ${vocabLocationChipDataFragment}
`;
