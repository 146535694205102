import React, { Children, FC } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';

type StepModalProps = {
  labels: string[];
  currentStep: number;
};

export const StepModal: FC<StepModalProps> = ({
  labels,
  currentStep,
  children,
}) => {
  return (
    <React.Fragment>
      <Stepper activeStep={currentStep}>
        {labels.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {children && Children.toArray(children)[currentStep]}
    </React.Fragment>
  );
};
