import * as React from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { ReadingMessageItemFragmentFragment } from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const ReadingWrapper = styled.div`
  width: 240px;
  border-radius: 4px;
  border: 1px solid #555;
  position: relative;
  background: white;
`;

const ReadingBodyWrapper = styled.div`
  padding: 5px;
`;

interface HeaderProps {
  hasImage: boolean;
}

export const readingMessageItemFragment = gql`
  fragment readingMessageItemFragment on ReadingMessageItem {
    title {
      text
      localizedText
    }
    items {
      ... on CardMessageItemBodyText {
        text
        localizedText
      }
      ... on CardMessageItemBodyImage {
        url
        aspect
      }
    }
    subtitle {
      text
      localizedText
    }
    image {
      url
      aspect
    }
  }
`;

const HeaderWrapper = styled.div<HeaderProps>(props => {
  const baseStyles = {
    margin: 0,
    padding: '5px',
  };
  if (!props.hasImage) return baseStyles;
  return {
    position: 'absolute',
    left: 0,
    top: 0,
    color: 'white',
    width: '100%',
    height: '100%',
    zIndex: 100,
    background:
      'linear-gradient(177deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.1) 40%, rgba(0,0,0,0) 100%)',
    ...baseStyles,
  };
});

const H3Wrapper = styled.h3<HeaderProps>(props => {
  return props.hasImage ? { color: 'white', margin: 0 } : { margin: 0 };
});

export const ReadingMessageItem = (
  item: ReadingMessageItemFragmentFragment,
) => (
  <CoursewareItemContainer type={item.__typename}>
    <ReadingWrapper>
      <div style={{ position: 'relative' }}>
        <HeaderWrapper hasImage={!!item.image}>
          {item.title ? (
            <H3Wrapper hasImage={!!item.image}>
              <LocalizedText item={item.title} />
            </H3Wrapper>
          ) : null}
          {item.subtitle ? <LocalizedText item={item.subtitle} /> : null}
        </HeaderWrapper>
        {item.image ? (
          <img src={item.image.url} style={{ minHeight: '100px' }} />
        ) : null}
      </div>
      <ReadingBodyWrapper>
        {item.items.map((bodyItem, index) => {
          if (bodyItem.__typename === 'CardMessageItemBodyText') {
            return (
              <div key={index}>
                <LocalizedText item={bodyItem} />
              </div>
            );
          }
          if (bodyItem.__typename === 'CardMessageItemBodyImage') {
            return <img key={index} src={bodyItem.url} />;
          }
          return null;
        })}
      </ReadingBodyWrapper>
    </ReadingWrapper>
  </CoursewareItemContainer>
);
