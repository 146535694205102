import * as React from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { CardMessageItem, cardMessageItemFragment } from './CardMessageItem';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { CardCarouselMessageItemFragmentFragment } from '../../generated/graphql';
import {
  TextCardMessageItem,
  textCardMessageItemFragment,
} from './TextCardMessageItem';
import {
  ImageAudioCardMessageItem,
  imageAudioCardMessageItemFragment,
} from './ImageAudioCardMessageItem';

export const cardCarouselMessageItemFragment = gql`
  fragment cardCarouselMessageItemFragment on CardCarouselMessageItem {
    items {
      ...cardMessageItemFragment
      ...textCardMessageItemFragment
      ...imageAudioCardMessageItemFragment
    }
  }
  ${cardMessageItemFragment}
  ${textCardMessageItemFragment}
  ${imageAudioCardMessageItemFragment}
`;

const CardWrapper = styled.div`
  margin: 10px 0;
`;

const Spacer = styled.div`
  width: 100%;
  height: 10px;
`;

export const CardCarouselMessageItem = (
  item: CardCarouselMessageItemFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <Spacer />
      {item.items.map((card, i) => {
        if (card.__typename === 'CardMessageItem') {
          return (
            <CardWrapper key={i}>
              <CardMessageItem {...card} />
            </CardWrapper>
          );
        }
        if (card.__typename === 'TextCardMessageItem') {
          return <TextCardMessageItem key={i} {...card} />;
        }
        if (card.__typename === 'ImageAudioCardMessageItem') {
          return (
            <CardWrapper key={i}>
              <ImageAudioCardMessageItem {...card} />
            </CardWrapper>
          );
        }
        return null;
      })}
    </CoursewareItemContainer>
  );
};
