import * as React from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { CardItem, cardReviewContentItemFragment } from './Card';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { CardCarouselReviewContentItemFragmentFragment } from '../../generated/graphql';
import { TextCardItem, textCardReviewContentItemFragment } from './TextCard';

export const cardCarouselReviewContentItemFragment = gql`
  fragment cardCarouselReviewContentItemFragment on CardCarouselReviewContentItem {
    items {
      ...cardReviewContentItemFragment
      ...textCardReviewContentItemFragment
    }
  }
  ${cardReviewContentItemFragment}
  ${textCardReviewContentItemFragment}
`;

const Spacer = styled.div`
  width: 100%;
  height: 1px;
`;

export const CardCarouselItem = (
  item: CardCarouselReviewContentItemFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <Spacer />
      {item.items.map((card, i) => {
        if (card.__typename === 'CardReviewContentItem') {
          return <CardItem key={i} {...card} />;
        }
        if (card.__typename === 'TextCardReviewContentItem') {
          return <TextCardItem key={i} {...card} />;
        }
        return null;
      })}
    </CoursewareItemContainer>
  );
};
