import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { WebLinkMessageItemFragmentFragment } from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const WebLinkWrapper = styled.div`
  width: 240px;
`;

export const webLinkMessageItemFragment = gql`
  fragment webLinkMessageItemFragment on WebLinkMessageItem {
    url
    title {
      text
      localizedText
    }
  }
`;

export const WebLinkMessageItem = (
  item: WebLinkMessageItemFragmentFragment,
) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <WebLinkWrapper>
        <a
          target="_blank"
          rel="noopener noreferrer"
          css={{ outline: 'none', width: '100%' }}
          href={item.url}
        >
          {item.title ? <LocalizedText item={item.title} /> : item.url}
        </a>
      </WebLinkWrapper>
    </CoursewareItemContainer>
  );
};
