import * as React from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import {
  ImageAudioCardMessageItemAudioFragmentFragment,
  ImageAudioCardMessageItemFragmentFragment,
} from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const CardWrapper = styled.div`
  width: 240px;
  border-radius: 4px;
  border: 1px solid #555;
  position: relative;
  background: white;
`;

const CardBodyWrapper = styled.div`
  padding: 5px;
`;

const HeaderAudio = styled.audio`
  outline: none;
  width: 100%;
  height: 30px;
`;

const TranscriptLine = styled.div<{ hide: boolean }>`
  color: ${props => (props.hide ? '#9AA' : '#999')};
  font-size: 12px;
  display: flex;
  gap: 5px;
  padding: 5px 0;
  align-items: center;
  text-decoration: ${props => (props.hide ? 'line-through' : 'none')};
`;

export const imageAudioCardMessageItemFragment = gql`
  fragment imageAudioCardMessageItemAudioFragment on CardMessageItemAudio {
    url
    duration
    transcripts {
      text
      localizedText
    }
  }
  fragment imageAudioCardMessageItemFragment on ImageAudioCardMessageItem {
    audio {
      ...imageAudioCardMessageItemAudioFragment
    }
    image {
      url
      aspect
    }
  }
`;

export const ImageAudioCardMessageItem = (
  item: ImageAudioCardMessageItemFragmentFragment,
) => {
  const renderTranscript = (
    transcripts: ImageAudioCardMessageItemAudioFragmentFragment['transcripts'],
  ) =>
    (transcripts || []).map((line, i) => (
      <TranscriptLine key={i} hide={false}>
        <LocalizedText item={line} />
      </TranscriptLine>
    ));

  return (
    <CoursewareItemContainer type={item.__typename}>
      <CardWrapper>
        <CardBodyWrapper>
          <div style={{ position: 'relative' }}>
            {item.image ? (
              <img src={item.image.url} style={{ minHeight: '100px' }} />
            ) : null}
          </div>
          {item.audio && (
            <>
              <HeaderAudio controls src={item.audio.url}>
                Your browser does not support the
                <code>audio</code> element.
              </HeaderAudio>
              {item.audio.transcripts
                ? renderTranscript(item.audio.transcripts)
                : null}
            </>
          )}
        </CardBodyWrapper>
      </CardWrapper>
    </CoursewareItemContainer>
  );
};
