import * as React from 'react';
import gql from 'graphql-tag';
import { Chip } from '@material-ui/core';
import { navigate } from '@reach/router';
import { ConfirmationDialog } from './ConfirmationDialog';

import { VocabLocationChipDataFragment } from '../generated/graphql';

interface Props {
  data: VocabLocationChipDataFragment;
  onDelete: (data: VocabLocationChipDataFragment) => void;
}

export const VocabLocationChip = ({ data, onDelete }: Props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleCancel = () => setDialogOpen(false);
  const handleClickDelete = () => setDialogOpen(true);
  const handleDeleteConfirmed = () => onDelete(data);

  return (
    <>
      <Chip
        label={data.sense?.word.word}
        onDelete={handleClickDelete}
        onClick={() => navigate(`/dictionary/sense/${data.sense?.id}`)}
      />
      <ConfirmationDialog
        open={dialogOpen}
        body="Are you sure you want to remove this vocab word from the lesson?"
        onCancel={handleCancel}
        onConfirm={handleDeleteConfirmed}
      />
    </>
  );
};

export const vocabLocationChipDataFragment = gql`
  fragment vocabLocationChipData on VocabLocation {
    id
    sense {
      id
      word {
        id
        word
      }
    }
  }
`;
