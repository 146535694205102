import { DialogContent, DialogActions, Button } from '@material-ui/core';
import * as React from 'react';
import { StepProps } from './StepProps';

export const DoneStep: React.FC<StepProps> = ({ onClose }) => {
  return (
    <>
      <DialogContent>done</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </>
  );
};
