import { Chip } from '@material-ui/core';
import * as React from 'react';

export interface VocabSuggestionChipProps {
  suggestion: string;
  onClick: (suggestion: string) => void;
}

export const VocabSuggestionChip: React.FC<VocabSuggestionChipProps> = ({
  suggestion,
  onClick,
}) => {
  return (
    <Chip
      onClick={() => onClick(suggestion)}
      variant="outlined"
      label={suggestion}
    />
  );
};
