import React, { FC } from 'react';
import { Dialog, DialogTitle, Box } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { TagVocabStep } from './TagVocabStep';
import { EnhanceSenseStep } from './EnhanceStep';
import { DoneStep } from './DoneStep';
import {
  SmartCourseLessonQueryVariables,
  StudyLanguage,
} from '../../generated/graphql';
import { StepModal } from './Steps';

export interface AddVocabToLessonDialogProps {
  lessonId: string;
  open: boolean;
  isSupplemental?: boolean;
  onClose: () => void;
  searchWord?: string;
}

const STEPS = ['Find the vocab word', 'Match with Lexicala', 'Done'];
const WL_STEPS = ['Find the vocab word', 'Done'];

const lessonLanguageQuery = gql`
  query SmartCourseLessonQuery($id: ID!) {
    node(id: $id) {
      ... on SmartCourseLesson {
        __typename
        course {
          language
        }
      }
    }
  }
`;

export const AddVocabToLessonDialog: FC<AddVocabToLessonDialogProps> = ({
  open,
  onClose,
  lessonId,
  searchWord,
  isSupplemental,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [newVocabSense, setNewVocabSense] = React.useState<string>('');
  const { data: lessonLanguageResult } = useQuery<
    {
      node?: {
        __typename: 'SmartCourseLesson';
        course: {
          language: StudyLanguage;
        };
      };
    },
    SmartCourseLessonQueryVariables
  >(lessonLanguageQuery, {
    variables: {
      id: lessonId,
    },
  });
  const lessonLanguage = lessonLanguageResult?.node?.course.language;
  const steps = lessonLanguage === StudyLanguage.En ? STEPS : WL_STEPS;

  const onStepClose = () => {
    onClose();
    setActiveStep(0);
  };

  const goToNextStep = () => {
    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  const createNewVocabComplete = (senseId: string) => {
    if (senseId) {
      setNewVocabSense(senseId);
    }
    goToNextStep();
  };

  return (
    <Box>
      <Dialog
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add {isSupplemental ? 'Supplemental' : 'Core'} Vocab Word
        </DialogTitle>
        <StepModal labels={steps} currentStep={activeStep}>
          <TagVocabStep
            onStepComplete={createNewVocabComplete}
            onClose={onStepClose}
            lessonId={lessonId}
            searchWord={searchWord}
            isSupplemental={!!isSupplemental}
            language={lessonLanguage}
          />
          {lessonLanguage === StudyLanguage.En && (
            <EnhanceSenseStep
              onClose={onStepClose}
              lessonId={lessonId}
              senseId={newVocabSense}
              onStepComplete={goToNextStep}
            />
          )}
          <DoneStep onClose={onStepClose} onStepComplete={onStepClose} />;
        </StepModal>
      </Dialog>
    </Box>
  );
};
