import React from 'react';
import { fromGlobalId } from 'graphql-relay';
import {
  Box,
  BoxProps,
  Button,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  infoBox: {
    border: '1px solid black',
    padding: '16px',
    marginBottom: '16px',
  },
}));

interface Props extends BoxProps {
  children: React.ReactNode;
}

export const InfoBox = (props: Props) => {
  const classes = useStyles();

  return <Box className={classes.infoBox} {...props} />;
};

export const CopyableID = ({ children }: { children: string }) => (
  <>
    <Button
      aria-label="copy"
      size="small"
      title="Copy Database ID"
      endIcon={<FileCopy fontSize="small" />}
      onClick={() => {
        navigator.clipboard.writeText(fromGlobalId(children).id);
      }}
    >
      {fromGlobalId(children).id}
    </Button>
    <IconButton
      aria-label="copy"
      size="small"
      title="Copy GraphQL ID"
      onClick={() => {
        navigator.clipboard.writeText(children);
      }}
    >
      <FileCopy fontSize="small" />
    </IconButton>
  </>
);
