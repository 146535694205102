import * as React from 'react';
import styled from '@emotion/styled';
import { Box, Collapse, IconButton, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { VideoMessageItemFragmentFragment } from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const VideoWrapper = styled.div``;

export const videoMessageItemFragment = gql`
  fragment videoMessageItemFragment on VideoMessageItem {
    url
    aspect
    duration
    vocab {
      text
      localizedText
      audioUrl
    }
    hideSubtitles
    subtitles {
      text
    }
  }
`;

export const VideoMessageItem = (item: VideoMessageItemFragmentFragment) => {
  return (
    <CoursewareItemContainer type={item.__typename}>
      <VideoWrapper>
        <video
          width="240"
          css={{ outline: 'none' }}
          controls
          src={item.url}
          preload="metadata"
        >
          Your browser does not support the
          <code>video</code> element.
        </video>
        {item.vocab || item.subtitles ? (
          <VideoVocabAndSubtitles item={item} />
        ) : null}
      </VideoWrapper>
    </CoursewareItemContainer>
  );
};

const TranscriptLine = styled.div<{ hide: boolean }>`
  color: ${props => (props.hide ? '#9AA' : '#999')};
  font-size: 12px;
  display: flex;
  gap: 5px;
  padding: 5px 0;
  align-items: center;
  text-decoration: ${props => (props.hide ? 'line-through' : 'none')};
`;

const VideoVocabAndSubtitles = ({
  item,
}: {
  item: VideoMessageItemFragmentFragment;
}) => {
  const [open, setOpen] = React.useState(false);
  const renderTranscript = (
    subtitles: VideoMessageItemFragmentFragment['subtitles'],
  ) =>
    (subtitles || []).map((line, i) => (
      <TranscriptLine key={i} hide={!!item.hideSubtitles}>
        {item.hideSubtitles ? '🤖' : '💬'}
        <LocalizedText item={line} />
      </TranscriptLine>
    ));

  const renderVocab = (vocab: VideoMessageItemFragmentFragment['vocab']) =>
    (vocab || []).map((vocabItem, i) => (
      <>
        <audio
          key={i}
          css={{ outline: 'none' }}
          controls
          src={vocabItem.audioUrl}
          preload="metadata"
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <Typography variant="caption">
          <LocalizedText item={vocabItem} />
        </Typography>
      </>
    ));

  return (
    <>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpen(!open)}
      >
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box m={1} p={1} bgcolor="white">
          <Typography variant="subtitle1">Vocab:</Typography>
          {item.vocab ? renderVocab(item.vocab) : null}
        </Box>
        <Box m={1} p={1} bgcolor="white">
          <Typography variant="subtitle1">Subtitles:</Typography>
          {item.subtitles ? renderTranscript(item.subtitles) : null}
        </Box>
      </Collapse>
    </>
  );
};
