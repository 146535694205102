import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { AudioReviewContentItemFragmentFragment } from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const AudioWrapper = styled.figure`
  width: 400px;
  min-height: 45px;
  border-radius: 45px;
  padding: 0 5px;
  background-size: contain;
  margin: 5px;
`;

const TranscriptLine = styled.div<{ hide: boolean }>`
  color: ${props => (props.hide ? '#9AA' : '#999')};
  font-size: 12px;
  display: flex;
  gap: 5px;
  padding: 5px 0;
  align-items: center;
  text-decoration: ${props => (props.hide ? 'line-through' : 'none')};
`;

export const audioReviewContentItemFragment = gql`
  fragment audioReviewContentItemFragment on AudioReviewContentItem {
    url
    duration
    transcripts {
      text
      localizedText
    }
    hideTranscripts
  }
`;

export const AudioItem = (item: AudioReviewContentItemFragmentFragment) => {
  const renderTranscript = (
    transcripts: AudioReviewContentItemFragmentFragment['transcripts'],
  ) =>
    (transcripts || []).map((line, i) => (
      <TranscriptLine key={i} hide={!!item.hideTranscripts}>
        {item.hideTranscripts ? '🤖' : '💬'}
        <LocalizedText item={line} />
      </TranscriptLine>
    ));

  return (
    <CoursewareItemContainer type={item.__typename}>
      <AudioWrapper>
        <audio
          css={{ outline: 'none' }}
          controls
          src={item.url}
          preload="metadata"
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        {item.transcripts ? renderTranscript(item.transcripts) : null}
      </AudioWrapper>
    </CoursewareItemContainer>
  );
};
