import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { CoursewareItemContainer } from './CoursewareItemContainer';
import { TextCardReviewContentItemFragmentFragment } from '../../generated/graphql';
import { LocalizedText } from '../LocalizedText';

const CardWrapper = styled.figure`
  width: 240px;
  border-radius: 4px;
  border: 1px solid #555;
  position: relative;
`;

const CardBodyWrapper = styled.div`
  padding: 5px;
`;

export const textCardReviewContentItemFragment = gql`
  fragment textCardReviewContentItemFragment on TextCardReviewContentItem {
    items {
      text
      localizedText
    }
  }
`;

export const TextCardItem = (
  item: TextCardReviewContentItemFragmentFragment,
) => (
  <CoursewareItemContainer type={item.__typename}>
    <CardWrapper>
      <CardBodyWrapper>
        {item.items.map((bodyItem, index) => (
          <p key={index}>
            <LocalizedText item={bodyItem} />
          </p>
        ))}
      </CardBodyWrapper>
    </CardWrapper>
  </CoursewareItemContainer>
);
