import * as React from 'react';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';

import Container from '../../common/Container';
import {
  SmartCourseLessonQuery,
  VocabLocationChipDataFragment,
} from '../../../generated/graphql';
import { normalizeId } from '../../../normalizeId';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';
import { AddVocabToLessonDialog } from '../../AddVocabToLessonDialog';
import {
  vocabLocationChipDataFragment,
  VocabLocationChip,
} from '../../VocabLocationChip';
import { VocabSuggestionChip } from '../../VocabSuggestionChip';
import { SmartCourseLessonNavigation } from './SmartCourseLessonNavigation';
import { CopyableID, InfoBox } from './InfoBox';
import { getLanguageName } from '../../common/getLanguageName';

const smartCourseLessonQuery = gql`
  query SmartCourseLesson($id: ID!) {
    node(id: $id) {
      ... on SmartCourseLesson {
        id
        title
        numberInCourse
        course {
          id
          title
          language
        }
        sections {
          id
          title
          numberInLesson
        }
        vocabLocations {
          ...vocabLocationChipData
          isSupplemental
        }
        vocabSuggestions
        supplementalVocabSuggestions
        roleplays {
          id
          title
          description
          imageUrl
          userRole
          userGoal
        }
      }
    }
  }
  ${vocabLocationChipDataFragment}
`;

export const removeVocabLocationMutation = gql`
  mutation RemoveVocabLocation($id: ID!) {
    adminDeleteVocabLocation(input: { id: $id }) {
      clientMutationId
    }
  }
`;

interface SmartCourseLessonPageProps {
  id: string;
}

export const SmartCourseLessonPage = (props: SmartCourseLessonPageProps) => {
  const id = normalizeId(props.id, 'SmartCourseLesson');

  const [
    coreVocabTaggingDialogOpen,
    setCoreVocabTaggingDialogOpen,
  ] = React.useState(false);
  const [
    supplementalVocabTaggingDialogOpen,
    setSupplementalVocabTaggingDialogOpen,
  ] = React.useState(false);

  const [
    vocabTaggingWordSuggestion,
    setVocabTaggingWordSuggestion,
  ] = React.useState<string | undefined>();

  const onCoreSuggestionPressed = (suggestion: string) => {
    setVocabTaggingWordSuggestion(suggestion);
    setCoreVocabTaggingDialogOpen(true);
    setSupplementalVocabTaggingDialogOpen(false);
  };

  const onSupplementalSuggestionPressed = (suggestion: string) => {
    setVocabTaggingWordSuggestion(suggestion);
    setCoreVocabTaggingDialogOpen(false);
    setSupplementalVocabTaggingDialogOpen(true);
  };

  const onVocabTaggingDialogClose = () => {
    setCoreVocabTaggingDialogOpen(false);
    setSupplementalVocabTaggingDialogOpen(false);
    setVocabTaggingWordSuggestion(undefined);
  };

  const { loading, error, data, refetch } = useQuery<SmartCourseLessonQuery>(
    smartCourseLessonQuery,
    { variables: { id } },
  );

  const [removeVocabLocation] = useMutation(removeVocabLocationMutation);
  const onPressDeleteVocabLocation = async (
    vocab: VocabLocationChipDataFragment,
  ) => {
    await removeVocabLocation({ variables: { id: vocab.id } });
    refetch();
  };

  if (!data && loading) return <CircularProgress />;
  if (error) return <GraphQLErrorDisplay error={error} />;
  if (data?.node?.__typename !== 'SmartCourseLesson') return 'Not found :(';
  const lesson = data.node;

  const coreVocabLocations = lesson.vocabLocations?.filter(
    loc => !loc.isSupplemental,
  );
  const supplementalVocabLocations = lesson.vocabLocations?.filter(
    loc => loc.isSupplemental,
  );

  return (
    <Container>
      <SmartCourseLessonNavigation
        lessonId={lesson.id}
      ></SmartCourseLessonNavigation>
      <InfoBox marginY={2}>
        <Box>
          <b>Course:</b>{' '}
          <Link to={`/smartCourse/${lesson.course.id}`}>
            {lesson.course.title}
          </Link>{' '}
          {getLanguageName(lesson.course.language)}
        </Box>
        <Box>
          <b>Course ID:</b>
          <CopyableID>{lesson.course.id}</CopyableID>
        </Box>
        <Box>
          <b>Lesson:</b>{' '}
          <Link to={`/smartCourse/lesson/${lesson.id}`}>
            {lesson.numberInCourse} - {lesson.title}
          </Link>
        </Box>
        <Box>
          <b>Lesson ID:</b>
          <CopyableID>{lesson.id}</CopyableID>
        </Box>
      </InfoBox>
      <Box>
        <Typography variant="h3" component="h1">
          {lesson.title}
        </Typography>
        <Typography variant="h4">Sections</Typography>
        <Divider />
      </Box>
      <Box marginY={2}>
        {lesson.sections.map(
          section =>
            section && (
              <div key={section.id}>
                <Link to={`/smartCourse/section/${section.id}`}>
                  {section.numberInLesson} - {section.title}
                </Link>
              </div>
            ),
        )}
      </Box>
      <Box mt={4} mb={2}>
        <Typography variant="h4">Vocabulary</Typography>
        <Divider />
      </Box>
      <Box>
        <Typography variant="subtitle1">Tagged Core Vocabulary</Typography>
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {coreVocabLocations?.map(vocab => (
          <Box key={vocab.id} mr={1} mt={1}>
            <VocabLocationChip
              onDelete={onPressDeleteVocabLocation}
              data={vocab}
            />
          </Box>
        ))}
        <Box mr={1} mt={1}>
          <Chip
            variant="outlined"
            icon={<AddBoxIcon />}
            label="add new"
            onClick={() => setCoreVocabTaggingDialogOpen(true)}
          />
        </Box>
      </Box>
      <Box mt={4}>
        <Typography variant="subtitle1">
          Tagged Supplemental Vocabulary
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {supplementalVocabLocations?.map(vocab => (
          <Box key={vocab.id} mr={1} mt={1}>
            <VocabLocationChip
              onDelete={onPressDeleteVocabLocation}
              data={vocab}
            />
          </Box>
        ))}
        <Box mr={1} mt={1}>
          <Chip
            variant="outlined"
            icon={<AddBoxIcon />}
            label="add new"
            onClick={() => setSupplementalVocabTaggingDialogOpen(true)}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <Box mt={4} mb={2}>
          <Typography variant="subtitle1">Suggested Vocab</Typography>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {lesson.vocabSuggestions?.map(suggestion => (
            <Box key={suggestion} mr={1} mt={1}>
              <VocabSuggestionChip
                onClick={onCoreSuggestionPressed}
                suggestion={suggestion}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box mt={2}>
        <Box mt={4} mb={2}>
          <Typography variant="subtitle1">
            Suggested Supplemental Vocab
          </Typography>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {lesson.supplementalVocabSuggestions?.map(suggestion => (
            <Box key={suggestion} mr={1} mt={1}>
              <VocabSuggestionChip
                onClick={onSupplementalSuggestionPressed}
                suggestion={suggestion}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <AddVocabToLessonDialog
        open={coreVocabTaggingDialogOpen}
        onClose={onVocabTaggingDialogClose}
        lessonId={lesson.id}
        searchWord={vocabTaggingWordSuggestion}
        isSupplemental={false}
      />
      <AddVocabToLessonDialog
        open={supplementalVocabTaggingDialogOpen}
        onClose={onVocabTaggingDialogClose}
        lessonId={lesson.id}
        searchWord={vocabTaggingWordSuggestion}
        isSupplemental={true}
      />
      <Box mt={4} mb={2}>
        <Typography variant="h4">Roleplays</Typography>
        <Divider />
      </Box>
      {lesson.roleplays?.map(roleplay => (
        <Box key={roleplay.id} mr={1} mt={1}>
          <InfoBox marginY={2}>
            <Box>
              <b>Title:</b> {roleplay.title}
            </Box>
            <Box>
              <b>ID:</b>
              <CopyableID>{roleplay.id}</CopyableID>
            </Box>
            <Box>
              <b>Description:</b> {roleplay.description}
            </Box>
            <Box>
              <b>User Role:</b> {roleplay.userRole}
            </Box>
            <Box>
              <b>User Goal:</b> {roleplay.userGoal}
            </Box>
            <Box>
              <b>Image:</b>
            </Box>
            <Box>
              <a href={roleplay.imageUrl ?? ''} target="window">
                <img src={roleplay.imageUrl ?? ''} width="100px" />
              </a>
            </Box>
          </InfoBox>
        </Box>
      ))}
    </Container>
  );
};
