import * as React from 'react';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { DialogFragmentFragment } from '../../generated/graphql';
import {
  dialogBaseSegmentFragment,
  DialogBaseSegment,
} from './DialogBaseSegment';
import {
  dialogQuestionSegmentFragment,
  DialogQuestionSegment,
} from './DialogQuestionSegment';
import {
  dialogTopicSegmentFragment,
  DialogTopicSegment,
} from './DialogTopicSegment';

const DialogWrapper = styled.div`
  padding: 5px;
`;

export const dialogFragment = gql`
  fragment dialogFragment on Dialog {
    segments {
      ...dialogBaseSegmentFragment
      ...dialogQuestionSegmentFragment
      ...dialogTopicSegmentFragment
    }
  }
  ${dialogBaseSegmentFragment}
  ${dialogQuestionSegmentFragment}
  ${dialogTopicSegmentFragment}
`;

export const Dialog = (item: DialogFragmentFragment) => {
  return (
    <DialogWrapper>
      {item.segments.map((child, i) => {
        if (child.__typename === 'DialogBaseSegment')
          return <DialogBaseSegment {...child} key={i} />;
        if (child.__typename === 'DialogQuestionSegment')
          return <DialogQuestionSegment {...child} key={i} />;
        if (child.__typename === 'DialogTopicSegment')
          return <DialogTopicSegment {...child} key={i} />;
        return null;
      })}
    </DialogWrapper>
  );
};
